import React, { useState, useEffect } from 'react'
import ConsumptionLogsPerDayComponent from './consumptionLogsPerDayComponent';
import TodayMacrosComponent from './todayMacrosComponent';


const ConsumptionLogListComponent = () => {
    const [basalMetabolicRate, setBasalMetabolicRate] = useState(localStorage.getItem('target_bmr'));
    const [target_fats, setTargetFats] = useState(localStorage.getItem('target_fats'));
    const [target_carbs, setTargetCarbs] = useState(localStorage.getItem('target_carbs'));
    const [target_protein, setTargetProtein] = useState(localStorage.getItem('target_protein'));

    return <React.Fragment>
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Consumption Logs For Today</h4>

                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                            <li className="breadcrumb-item active">Foods</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-12">

            </div>
        </div>

        <div className="row">
            <div className="col-xl-8">
                <ConsumptionLogsPerDayComponent />
            </div>
            <div className="col-xl-4">
                <TodayMacrosComponent targetFats={target_fats} targetCarbs={target_carbs} targetProtein={target_protein} basalMetabolicRate={basalMetabolicRate} />
            </div>
        </div>
    </React.Fragment >;
}

export default ConsumptionLogListComponent;
