import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import HeaderComponent from './layout/headerComponent';
import DashboardComponent from './pages/dashboard/dashboardComponent';
import ConsumptionLogListComponent from './pages/consumption_logs/consumptionLogListComponent';
import FooterComponent from './layout/footerComponent';
import MealListComponent from './pages/meals/mealListComponent';
import ProfileComponent from './pages/profile/profileComponent';

class MainRouterComponent extends Component {
    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <HeaderComponent authEmail={this.props.authEmail}></HeaderComponent>

                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <Switch>
                                    <Route path="/" exact>
                                        <DashboardComponent authToken={this.props.authToken}></DashboardComponent>
                                    </Route>

                                    <Route path="/meals" exact component={() => <MealListComponent authToken={this.props.authToken}></MealListComponent>} />

                                    <Route path="/consumption_logs" exact component={() => <ConsumptionLogListComponent authToken={this.props.authToken}></ConsumptionLogListComponent>} />

                                    <Route path="/profile" exact component={() => <ProfileComponent authToken={this.props.authToken}></ProfileComponent>} />
                                </Switch>
                            </div>
                        </div>

                        <FooterComponent></FooterComponent>
                    </div>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}

export default MainRouterComponent;