import React, { Component } from 'react'
import LoginComponent from './components/pages/auth/loginComponent';
import MainRouterComponent from './components/mainRouterComponent';

class App extends Component {
    state = {
        authToken: null,
        authEmail: null
    };

    updateCredentials = (newToken, newEmail) => {
        this.setState({ authToken: newToken, authEmail: newEmail });
    }

    renderLoginForm() {
        return <LoginComponent updateCredentials={this.updateCredentials} />;
    }

    renderContent() {
        return <MainRouterComponent authToken={this.state.authToken} authEmail={this.state.authEmail} />;
    }

    render() {
        return this.state.authToken ? this.renderContent() : this.renderLoginForm();
    }
}

export default App;
