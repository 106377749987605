import React, { useState, useEffect } from 'react'
import { useQuery, gql } from "@apollo/client";
import { getDate } from './../../utils/getDate';

const TodayMacrosComponent = (props) => {
    const DAY_ENTRY = gql`
        query GetDayEntry($date: String!) {
            dayEntry(date: $date) {
                date
                consumptionTotals {
                    kcal
                    carbs
                    fats
                    protein
                }
                dayMeasurement {
                    kcalsBurned
                }
            }
        }
    `;

    const { loading, error, data } = useQuery(DAY_ENTRY, {
        variables: {
            date: getDate(0)
        }
    });

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // make sure that the HTML is rendered before initializing the chart
    });

    // rendering below

    if (loading) {
        return <p>Loading TodayMacrosComponent...</p>;
    }

    if (error) {
        return <p>Error Loading TodayMacrosComponent :(</p>;
    }

    var targetKcals = Number(props.basalMetabolicRate);
    if (data.dayEntry && data.dayEntry.dayMeasurement) {
        targetKcals += data.dayEntry.dayMeasurement.kcalsBurned / 10;
    }

    var kcalDifference = Math.round(targetKcals - data.dayEntry.consumptionTotals.kcal / 10)
    if (kcalDifference > 0) {
        kcalDifference = kcalDifference + " left";
    } else {
        kcalDifference = Math.abs(kcalDifference) + " over";
    }

    var fatsDifference = Math.round((props.targetFats - data.dayEntry.consumptionTotals.fats) / 10)
    if (fatsDifference > 0) {
        fatsDifference = fatsDifference + " left"
    } else {
        fatsDifference = Math.abs(fatsDifference) + " over"
    }

    var carbsDifference = Math.round((props.targetCarbs - data.dayEntry.consumptionTotals.carbs) / 10)
    if (carbsDifference > 0) {
        carbsDifference = carbsDifference + " left"
    } else {
        carbsDifference = Math.abs(carbsDifference) + " over"
    }

    var proteinDifference = Math.round((props.targetProtein - data.dayEntry.consumptionTotals.protein) / 10)
    if (proteinDifference > 0) {
        proteinDifference = proteinDifference + " left"
    } else {
        proteinDifference = Math.abs(proteinDifference) + " over"
    }

    return <React.Fragment>

        <div className="card">
            <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Daily Macronutruient Targets</h4>
            </div>
            <div className="card-body p-2">
                <div className="card bg-light overflow-hidden">
                    <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h6 className="mb-0"><b>{Math.round(data.dayEntry.consumptionTotals.kcal / 10)}</b> <span className="text-muted">Kcal eaten</span></h6>
                            </div>
                            <div className="flex-shrink-0">
                                <h6 className="mb-0">{kcalDifference}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="progress bg-soft-primary rounded-0">
                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: Math.round(data.dayEntry.consumptionTotals.kcal * 100 / targetKcals / 10) + '%' }} aria-valuenow={Math.round(data.dayEntry.consumptionTotals.kcal * 100 / targetKcals / 10)} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>

                <div className="card bg-light overflow-hidden">
                    <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h6 className="mb-0"><b>{Math.round(data.dayEntry.consumptionTotals.fats / 10)}</b> <span className="text-muted">g of fats eaten</span></h6>
                            </div>
                            <div className="flex-shrink-0">
                                <h6 className="mb-0">{fatsDifference}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="progress bg-soft-warning rounded-0">
                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: Math.round(data.dayEntry.consumptionTotals.fats * 100 / props.targetFats) + '%' }} aria-valuenow={Math.round(data.dayEntry.consumptionTotals.carbs * 100 / props.targetCarbs)} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>

                <div className="card bg-light overflow-hidden">
                    <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h6 className="mb-0"><b>{Math.round(data.dayEntry.consumptionTotals.carbs / 10)}</b> <span className="text-muted">g of carbs eaten</span></h6>
                            </div>
                            <div className="flex-shrink-0">
                                <h6 className="mb-0">{carbsDifference}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="progress bg-soft-info rounded-0">
                        <div className="progress-bar bg-info" role="progressbar" style={{ width: Math.round(data.dayEntry.consumptionTotals.carbs * 100 / props.targetCarbs) + '%' }} aria-valuenow={Math.round(data.dayEntry.consumptionTotals.carbs * 100 / props.targetCarbs)} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>

                <div className="card bg-light overflow-hidden">
                    <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h6 className="mb-0"><b>{Math.round(data.dayEntry.consumptionTotals.protein / 10)}</b> <span className="text-muted">g of protein eaten</span></h6>
                            </div>
                            <div className="flex-shrink-0">
                                <h6 className="mb-0">{proteinDifference}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="progress bg-soft-danger rounded-0">
                        <div className="progress-bar bg-danger" role="progressbar" style={{ width: Math.round(data.dayEntry.consumptionTotals.protein * 100 / props.targetProtein) + '%' }} aria-valuenow={Math.round(data.dayEntry.consumptionTotals.protein * 100 / props.targetProtein)} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default TodayMacrosComponent;
