import AppleSignin from 'react-apple-signin-auth';
import React from 'react'

/** Apple Signin button */
const MyAppleSigninButton = (props) => {

    if (process.env.NODE_ENV == 'development') {
        return <button onClick={() => props.success_handler()}>Login Development</button>
    }

    return <React.Fragment>
        <AppleSignin
            /** Auth options passed to AppleID.auth.init() */
            authOptions={{
                /** Client ID - eg: 'com.example.com' */
                clientId: 'com.snackfolio.my',
                /** Requested scopes, seperated by spaces - eg: 'email name' */
                scope: 'email name',
                /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                redirectURI: 'https://my.snackfolio.com/progress',
                /** State string that is returned with the apple response */
                state: 'state',
                /** Nonce */
                nonce: 'nonce',
                /** Uses popup auth instead of redirection */
                usePopup: true,
            }} // REQUIRED
            /** General props */
            uiType="dark"
            /** className */
            className="apple-auth-btn"
            /** Removes default style tag */
            noDefaultStyle={false}
            /** Allows to change the button's children, eg: for changing the button text */
            buttonExtraChildren="Sign in with Apple"
            /** Extra controlling props */
            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
            onSuccess={(response) => props.success_handler(response)} // default = undefined
            /** Called upon signin error */
            onError={(error) => props.error_handler(error)} // default = undefined
            /** Skips loading the apple script if true */
            skipScript={false} // default = undefined
            /** Apple image props */
            iconProp={{ style: { marginTop: '10px' } }} // default = undefined
        /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
        // render={(props) => <button {...props}>Sign in with Apple</button>}
        />
    </React.Fragment>
};

export default MyAppleSigninButton;