import React, { useState, useEffect } from 'react'
import { useQuery, gql } from "@apollo/client";
import { Chart, registerables } from 'chart.js';
import { getDate } from '../../utils/getDate';

const WeightChartComponent = (props) => {
    const DAY_ENTRY = gql`
        query GetDayEntry($date: String!) {
            dayEntry(date: $date) {
                date
                consumptionTotals {
                    kcal
                    carbs
                    fats
                    protein
                }
                dayMeasurement {
                    kcalsBurned
                    weightInGrams
                }
            }
        }
    `;

    const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
    const [remainingDaysToLoad, setRemainingDaysToLoad] = useState(props.daysToLoad - 1);
    const [dayEntries, setDayEntries] = useState([]);
    const [weightChart, setweightChart] = useState(null);
    const [totalKcalsBudget, setTotalKcalsBudget] = useState(0);

    useEffect(() => {
        if (dayEntries.length > 0) {
            refreshChart(remainingDaysToLoad + 1 - props.daysToLoad);
        }
    }, [dayEntries]);

    const { loading, error, data } = useQuery(DAY_ENTRY, {
        variables: {
            date: getDate(remainingDaysToLoad - props.daysToLoad + 1)
        },
        onCompleted: (data) => {
            setDayEntries([data.dayEntry, ...dayEntries])

            if (remainingDaysToLoad > 0) {
                setRemainingDaysToLoad(remainingDaysToLoad - 1);
            }
        }
    });

    const initializeweightChart = () => {
        // make sure this is only initialized once
        if (weightChart == null) {
            const ctx = document.getElementById('weightChart');
            Chart.register(...registerables);
            setweightChart(new Chart(ctx, {
                type: 'line',
                data: {
                    labels: [],
                    datasets: [
                        {
                            yAxisID: 'yLeft',
                            label: 'Weight',
                            data: [],
                            borderColor: '#999999',
                            backgroundColor: "rgba(99, 99, 99, 0.2)",
                            tension: 0.1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 0,
                            pointHoverRadius: 5,
                            barPercentage: 1.0
                        }
                    ]
                },
                options: {
                    responsive: true,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        yLeft: {
                            beginAtZero: false,
                            position: 'left',
                            title: 'KG',
                            grid: {
                                drawOnChartArea: true,
                                color: function (context) {
                                    if (context.tick.value == 0) {
                                        return '#CCCCCC';
                                    }
                                },
                            },
                            stacked: false
                        },
                        x: {
                            drawBorder: false,
                            grid: {
                                drawOnChartArea: false
                            },
                            stacked: true
                        }
                    }
                }
            }));
        }
    }

    const refreshChart = (offset) => {
        if (offset === 0) {
            weightChart.data.labels.unshift('Today');
        } else {
            weightChart.data.labels.unshift(getDate(offset));
        }


        var target = props.basalMetabolicRate;
        if (dayEntries[0].dayMeasurement) {
            // if energy burned is available, add it to the basal metabolic rate
            target += dayEntries[0].dayMeasurement.kcalsBurned / 10
        }

        var kcalsRemaining = Math.round(target - (dayEntries[0].consumptionTotals.kcal / 10));

        setTotalKcalsBudget(totalKcalsBudget + kcalsRemaining);

        weightChart.data.datasets.forEach((dataset) => {
            if (dayEntries[0].dayMeasurement) {
                if (dataset.label === 'Weight') {
                    dataset.data.unshift(dayEntries[0].dayMeasurement.weightInGrams / 1000);
                }
            }
            else {
                dataset.data.unshift(0);
            }
        });
        weightChart.update();
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // make sure that the HTML is rendered before initializing the chart
        if (document.getElementById('weightChart') && weightChart == null) {
            initializeweightChart();
        }
    });

    // rendering below

    if (loading && !initialLoadCompleted) {
        setInitialLoadCompleted(true);
        return <p>Loading Weight Chart...</p>;
    }

    if (error) {
        return <p>Error Loading Weight Chart :(</p>;
    }

    return <React.Fragment>
        <div className="row">
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header border-0 align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Body weight</h4>
                    </div>

                    <div className="card-body p-2 pb-2">
                        <canvas id="weightChart" width="400" height="50"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default WeightChartComponent;
