import React, { Component } from 'react'
import EnergyEatenChartComponent from './energyEatenChartComponent'
import EnergyChartComponent from './energyChartComponent';
import MacrosChartComponent from './macrosChartComponent';
import WeightChartComponent from './weightChartComponent';

const DashboardComponent = (props) => {
    const days_to_load = 30;
    const basalMetabolicRate = localStorage.getItem('target_bmr');

    return <React.Fragment>
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Dashboard</h4>

                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                            <li className="breadcrumb-item active">Foods</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <EnergyChartComponent daysToLoad={days_to_load} basalMetabolicRate={basalMetabolicRate} />
        <EnergyEatenChartComponent daysToLoad={days_to_load} basalMetabolicRate={basalMetabolicRate} />
        <MacrosChartComponent daysToLoad={days_to_load} basalMetabolicRate={basalMetabolicRate} />
        <WeightChartComponent daysToLoad={days_to_load} basalMetabolicRate={basalMetabolicRate} />

    </React.Fragment>
}

export default DashboardComponent;
