import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class HeaderComponent extends Component {
    handleLogout = () => {
        localStorage.removeItem("email");
        localStorage.removeItem("auth_token");
        window.location.reload();
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="layout-width">
                        <div className="navbar-header">
                            <div className="d-flex">
                                <div className="navbar-brand-box horizontal-logo">
                                    <a href="/" className="logo logo-dark">
                                        <span className="logo-sm">
                                            <img src="assets/images/logo-sm.png" alt="" height="22" />
                                        </span>
                                        <span className="logo-lg">
                                            <img className="me-1" src="/snackfolio_icon.png" alt="SnackFolio" height="30" />
                                            <img className="me-4" src="/snackfolio_name.png" alt="SnackFolio" height="30" />
                                        </span>
                                    </a>
                                </div>

                                <button type="button"
                                    className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                    id="topnav-hamburger-icon">
                                    <span className="hamburger-icon">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </span>
                                </button>



                            </div>

                            <div className="d-flex align-items-center">

                                <div className="dropdown d-md-none topbar-head-dropdown header-item">
                                    <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                        id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i className="bx bx-search fs-22"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                        aria-labelledby="page-header-search-dropdown">
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Search ..."
                                                        aria-label="Recipient's username" />
                                                    <button className="btn btn-primary" type="submit"><i
                                                        className="mdi mdi-magnify"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="dropdown ms-sm-3 header-item topbar-user">
                                    <button type="button" className="btn" id="page-header-user-dropdown"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="d-flex align-items-center">
                                            <i className="fs-1 ri-account-circle-fill"></i>
                                            <span className="text-start ms-xl-2">
                                                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{this.props.authEmail}</span>
                                                <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Options <i className="m-6 ri-arrow-down-s-line"></i></span>
                                            </span>
                                        </span>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" href="/" onClick={this.handleLogout}><i
                                            className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                                className="align-middle" data-key="t-logout">Logout</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="app-menu navbar-menu">
                    <div className="navbar-brand-box">
                        <a href="index.html" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src="assets/images/logo-sm.png" alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src="assets/images/logo-dark.png" alt="" height="17" />
                            </span>
                        </a>
                        <a href="index.html" className="logo logo-light">
                            <span className="logo-sm">
                                <img src="assets/images/logo-sm.png" alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src="assets/images/logo-light.png" alt="" height="17" />
                            </span>
                        </a>
                        <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                            id="vertical-hover">
                            <i className="ri-record-circle-line"></i>
                        </button>
                    </div>

                    <div id="scrollbar">
                        <div className="container-fluid">

                            <div id="two-column-menu">
                            </div>
                            <ul className="navbar-nav" id="navbar-nav">
                                <li className="menu-title"><span data-key="t-menu">Menu</span></li>

                                <li className="nav-item">
                                    <Link to="/" className="nav-link menu-link">
                                        <i className="ri-pie-chart-2-fill"></i> <span>Dashboard</span>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/meals" className="nav-link menu-link">
                                        <i className="ri-stack-fill"></i> <span>Meals</span>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/consumption_logs" className="nav-link menu-link">
                                        <i className="ri-list-unordered"></i> <span>Logs</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
};

export default HeaderComponent;