import React from 'react'
import { useQuery, gql } from "@apollo/client";
import { Link } from 'react-router-dom';

const MealListComponent = () => {
    const MEAL_INDEX = gql`
        query GetMeals(
            $limit: Int,
            $offset: Int
        ){
            meals(
                limit: $limit
                offset: $offset
            ) {
                id
                name
                updatedAt
                macrosPer100g {
                    kcal
                    fats
                    carbs
                    protein
                }
                portions {
                    name
                    quantityInDecigrams
                }
                mealComponents {
                    food {
                        name
                    }
                    quantityInDecigrams
                }
            }
        }
    `;

    const { loading, error, data } = useQuery(MEAL_INDEX, {
        variables: {
            limit: 50,
            offset: 0
        }
    });

    if (loading) return <p>Loading meals...</p>;
    if (error) return <p>Error :(</p>;

    const goToPreviousPage = () => {
        if (this.state.current_page > 1) {
            this.setState({ current_page: this.state.current_page - 1 }, () => {
                this.loadFoods();
            });
        }
    };

    const goToNextPage = () => {
        if (this.state.current_page < this.state.total_pages) {
            this.setState({ current_page: this.state.current_page + 1 }, () => {
                this.loadFoods();
            });
        }
    };

    return <React.Fragment>
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Meals</h4>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Previously Created Meals</h4>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive table-card">
                            <table
                                className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead className="text-muted table-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Macronutrients</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {data.meals.map(meal => (
                                        <tr key={meal.id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        <h4 class="card-title mb-0 flex-grow-1">
                                                            {meal.name}
                                                        </h4>
                                                        <ul>
                                                            {meal.mealComponents.map(component => (
                                                                <li>
                                                                    {component.food.name}
                                                                    &nbsp;
                                                                    <span className="text-muted me-4">({component.quantityInDecigrams / 10} grams)</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {meal.portions[0].quantityInDecigrams / 10}
                                                <br />
                                                <span className="text-muted me-4">grams per portion</span>
                                            </td>
                                            <td>
                                                <span className="text-muted me-4">{Math.ceil(meal.macrosPer100g.kcal * meal.portions[0].quantityInDecigrams / 10000)} kcal</span>
                                                <br />
                                                <span className="text-warning me-4">{Math.ceil(meal.macrosPer100g.fats * meal.portions[0].quantityInDecigrams / 10000)} fats</span>
                                                &nbsp;
                                                <span className="text-secondary me-4">{Math.ceil(meal.macrosPer100g.carbs * meal.portions[0].quantityInDecigrams / 10000)} carbs</span>
                                                &nbsp;
                                                <span className="text-danger me-4">{Math.ceil(meal.macrosPer100g.protein * meal.portions[0].quantityInDecigrams / 10000)} protein</span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>;
}

export default MealListComponent;