import React, { useEffect } from 'react'
import MyAppleSigninButton from './appleSignInButton'

const LoginComponent = (props) => {
    useEffect(() => {
        checkPreviousCredentials();
    });

    const checkPreviousCredentials = () => {
        const email = localStorage.getItem("email");
        const auth_token = localStorage.getItem("auth_token");
        if (email && auth_token) {
            props.updateCredentials(auth_token, email);
        }
    };

    const error_handler = (error) => {
        console.log(error)
    }

    const success_handler = (response) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                response: response,
                client: 'web'
            })
        };
        fetch(process.env.REACT_APP_API_URL + '/apple/signin', requestOptions)
            .then(res => res.json())
            .then((data) => {
                props.updateCredentials(data.auth_token, data.name);
                localStorage.setItem('email', data.name);
                localStorage.setItem('auth_token', data.auth_token);
                localStorage.setItem('target_fats', data.target_fats);
                localStorage.setItem('target_carbs', data.target_carbs);
                localStorage.setItem('target_protein', data.target_protein);
                localStorage.setItem('target_bmr', data.target_bmr);
            })
            .catch(console.log)
    }

    return <React.Fragment>
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay"></div>
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card overflow-hidden">
                                <div className="row g-0">


                                    <div className="col-lg-12">
                                        <div className="p-lg-5 p-4">
                                            <div className="mt-5 text-center">
                                                <img className="mb-5 mt-5" src="/snackfolio_logo.png" alt="SnackFolio" />

                                                <br />

                                                <MyAppleSigninButton
                                                    error_handler={error_handler}
                                                    success_handler={success_handler}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-center">
            <main className="form-signin">
                <img className="mb-5 mt-5" src="/snackfolio_logo.png" alt="SnackFolio" />

                <br />

                <MyAppleSigninButton
                    error_handler={error_handler}
                    success_handler={success_handler}
                />
            </main>
        </div>
    </React.Fragment>
}

export default LoginComponent;