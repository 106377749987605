import React, { useState, useEffect } from 'react'
import { useQuery, gql } from "@apollo/client";
import { Chart, registerables } from 'chart.js';
import { getDate } from '../../utils/getDate';

const EnergyChartComponent = (props) => {
    const DAY_ENTRY = gql`
        query GetDayEntry($date: String!) {
            dayEntry(date: $date) {
                date
                consumptionTotals {
                    kcal
                    carbs
                    fats
                    protein
                }
                dayMeasurement {
                    kcalsBurned
                    weightInGrams
                }
            }
        }
    `;

    const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
    const [remainingDaysToLoad, setRemainingDaysToLoad] = useState(props.daysToLoad - 1);
    const [dayEntries, setDayEntries] = useState([]);
    const [energyChart, setEnergyChart] = useState(null);
    const [totalKcalsBudget, setTotalKcalsBudget] = useState(0);

    useEffect(() => {
        if (dayEntries.length > 0) {
            refreshChart(remainingDaysToLoad + 1 - props.daysToLoad);
        }
    }, [dayEntries]);

    const { loading, error } = useQuery(DAY_ENTRY, {
        variables: {
            date: getDate(remainingDaysToLoad - props.daysToLoad + 1)
        },
        onCompleted: (data) => {
            setDayEntries([data.dayEntry, ...dayEntries])

            if (remainingDaysToLoad > 0) {
                setRemainingDaysToLoad(remainingDaysToLoad - 1);
            }
        }
    });

    const initializeEnergyChart = () => {
        // make sure this is only initialized once
        if (energyChart == null) {
            const ctx = document.getElementById('energyChart');
            Chart.register(...registerables);
            setEnergyChart(new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [
                        {
                            yAxisID: 'yKcals',
                            label: 'Kcals Remaining',
                            data: [],
                            borderColor: '#076d20',
                            backgroundColor: '#076d20',
                            tension: 0.1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 0,
                            pointHoverRadius: 5,
                            barPercentage: 1.0
                        },
                        {
                            yAxisID: 'yKcals',
                            label: 'Kcals Overconsumed',
                            data: [],
                            borderColor: '#FF0000',
                            backgroundColor: '#FF0000',
                            tension: 0.1,
                            fill: true,
                            pointStyle: 'circle',
                            pointRadius: 0,
                            pointHoverRadius: 5,
                            barPercentage: 1.0
                        }
                    ]
                },
                options: {
                    responsive: true,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        yKcals: {
                            beginAtZero: false,
                            position: 'left',
                            title: 'Kcals',
                            grid: {
                                drawOnChartArea: true,
                                color: function (context) {
                                    if (context.tick.value === 0) {
                                        return '#CCCCCC';
                                    }
                                },
                            },
                            stacked: true
                        },
                        x: {
                            drawBorder: false,
                            grid: {
                                drawOnChartArea: false
                            },
                            stacked: true
                        }
                    }
                }
            }));
        }
    }

    const refreshChart = (offset) => {
        if (offset === 0) {
            energyChart.data.labels.unshift('Today');
        } else {
            energyChart.data.labels.unshift(getDate(offset));
        }

        var target = Number(props.basalMetabolicRate);
        if (dayEntries[0].dayMeasurement) {
            // if energy burned is available, add it to the basal metabolic rate
            target += dayEntries[0].dayMeasurement.kcalsBurned / 10
        }

        var kcalsRemaining = Math.round(target - (dayEntries[0].consumptionTotals.kcal / 10));

        setTotalKcalsBudget(totalKcalsBudget + kcalsRemaining);

        energyChart.data.datasets.forEach((dataset) => {
            if (kcalsRemaining > 0) {
                if (dataset.label === 'Kcals Remaining') {
                    dataset.data.unshift(-kcalsRemaining);
                }
                if (dataset.label === 'Kcals Overconsumed') {
                    dataset.data.unshift(0);
                }
            } else {
                if (dataset.label === 'Kcals Remaining') {
                    dataset.data.unshift(0);
                }
                if (dataset.label === 'Kcals Overconsumed') {
                    dataset.data.unshift(-kcalsRemaining);
                }
            }
        });
        energyChart.update();
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // make sure that the HTML is rendered before initializing the chart
        if (document.getElementById('energyChart') && energyChart == null) {
            initializeEnergyChart();
        }
    });

    // rendering below

    if (loading && !initialLoadCompleted) {
        setInitialLoadCompleted(true);
        return <p>Loading Energy Chart...</p>;
    }

    if (error) {
        return <p>Error Loading Energy Chart :(</p>;
    }

    return <React.Fragment>
        <div className="row">
            <div className="col-xl-12">
                <div className="card">
                    <div className="card-header border-0 align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Over and Under Consumption</h4>

                    </div>
                    <div className="card-body p-2 pb-2">
                        <canvas id="energyChart" width="400" height="100"></canvas>
                        <p className="p-2">This chart shows the difference between your targeted consumption and your real consumption. To ensure a consistency in weight and progress toward your goals, aim to have little variation.</p>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default EnergyChartComponent;
