import React from 'react'
import { useQuery, gql } from "@apollo/client";
import { getDate } from './../../utils/getDate';

const ConsumptionLogsPerDayComponent = () => {
    const DAY_ENTRY = gql`
        query GetDayEntry($date: String!) {
            dayEntry(date: $date) {
                date
                consumptionTotals {
                    kcal
                    carbs
                    fats
                    protein
                }
                dayMeasurement {
                    kcalsBurned
                    weightInGrams
                }
                consumptionLogs {
                    id
                    imgThumbnail
                    quantityInDecigrams
                    groupName
                    kcal
                    fats
                    carbs
                    protein
                    description
                    consumedAt
                    food {
                        name
                    }
                    meal {
                        name
                    }
                }
            }
        }
    `;

    const todayDate = getDate();

    const { loading, error, data } = useQuery(DAY_ENTRY, {
        variables: {
            date: todayDate
        }
    });

    if (loading) return <p>Loading Consumption logs for {todayDate}...</p>;
    if (error) return <p>Error :(</p>;

    return <React.Fragment>
        {data.dayEntry.consumptionLogs
            .map(log => log.groupName)
            .reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []).map(groupName => (
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">{groupName}</h4>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive table-card">
                            <table
                                className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead className="text-muted table-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Energy</th>
                                        <th scope="col">Macros</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.dayEntry.consumptionLogs
                                        .filter(log => log.groupName === groupName)
                                        .map(log => (<tr key={log.id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        {log.food === null && log.meal === null ?
                                                            <i className="ri-edit-box-line h1"></i>
                                                            :
                                                            log.meal !== null ?
                                                                <i className="mdi mdi-silverware-fork-knife h1"></i>
                                                                :
                                                                log.imgThumbnail != null && log.imgThumbnail != "" ?
                                                                    <img src={log.imgThumbnail} alt="" className="avatar-xs rounded-circle" />
                                                                    :
                                                                    <i className="mdi mdi-file-image-remove h1"></i>
                                                        }
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        {log.food !== null ? log.food.name : log.meal !== null ? log.meal.name : log.description}
                                                        <br />
                                                        <small>{log.consumedAt}</small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span>{log.quantityInDecigrams / 10} grams</span>
                                            </td>
                                            <td>
                                                {log.kcal / 10} Kcal
                                            </td>
                                            <td>
                                                <span className="text-warning">{log.fats / 10} g</span>
                                                &nbsp;
                                                <span className="text-default">{log.carbs / 10} g</span>
                                                &nbsp;
                                                <span className="text-danger">{log.protein / 10} g</span>
                                            </td>
                                        </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ))
        }
    </React.Fragment>;
}

export default ConsumptionLogsPerDayComponent;
